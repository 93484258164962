import {useContext, useState} from 'react';
import appContext from "./appContext";
import {Typography, Toolbar, IconButton, Box, AppBar, Popover, TextField, List, ListItem, ListSubheader, Divider} from '@mui/material';
import {Bluetooth, AccountCircle} from '@mui/icons-material';

export default function MainAppBar(props) {
  const {title} = props
  const {profile, setProfile} = useContext(appContext)
  const [anchorEl, setAnchorEl] = useState(null);
  const [ftpErrorState, setFtpErrorState] = useState(false)
  const [ftpErrorValue, setFtpErrorValue] = useState(profile.ftp)

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    if(ftpErrorState) {
      setFtpErrorState(false)
      setFtpErrorValue(profile.ftp)
    }
  };

  const handleFTPChange = (event) => {
    if ( Number.isNaN(Number(event.target.value)) || event.target.value < 0 || event.target.value === "") {
      setFtpErrorState(true)
      setFtpErrorValue(event.target.value)
    } else {
      if(ftpErrorState) {
        setFtpErrorState(false)
        setFtpErrorValue(event.target.value)
      }
      const newProfile = {ftp: Number(event.target.value)}
      setProfile({ftp: Number(event.target.value)});
      localStorage.setItem("profile", JSON.stringify(newProfile))
    }
  };

  const stravaAuthorize = async (event) => {
    const params = new URLSearchParams({
      "client_id": "62864",
      "response_type": "code",
      "redirect_uri": "https://localhost:8000/stravaAuth",
      "approval_prompt": "auto",
      "scope": "activity:write"
    });

    window.location.replace(`https://www.strava.com/oauth/authorize?${params.toString()}`)
  }

  const open = Boolean(anchorEl);
  const id = open ? 'profile-popover' : undefined;

  let ftpTextField = (<TextField id="outlined-basic" label="FTP" variant="outlined" value={profile.ftp} onChange={handleFTPChange} />)
  if(ftpErrorState) {
    ftpTextField = (<TextField error id="outlined-basic" label="Error" variant="outlined" helperText="Enter a positive number" value={ftpErrorValue} onChange={handleFTPChange} />)
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1, my: 2, textAlign: 'left'}}>
            {title}
          </Typography>
          <div>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="profile"
            sx={{ mr: 2 }}
            onClick={handleOpen}
          >
            <AccountCircle />
          </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <List
          sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              Profile
            </ListSubheader>
          }
        >
          <ListItem>
            {ftpTextField}
          </ListItem>
          {/* <ListItem>
            <Button variant="text" onClick={stravaAuthorize}>Connect to Strava</Button>
          </ListItem> */}
        </List>
      </Popover>
    </Box>
  );
}