import {forwardRef, useContext, useState, useEffect} from 'react';
import {PlayArrow, Pause, Close} from '@mui/icons-material';
import {Container, Fab, Grid, Slide, Typography, IconButton, Toolbar, AppBar, Dialog, Button} from '@mui/material'
import PowerCard from './PowerCard'
import CadenceCard from './CadenceCard'
import HeartCard from './HeartCard'
import WorkoutTimeCard from './WorkoutTimeCard'
import WorkoutGraph2 from './WorkoutGraph2'

import WorkoutCompleteDialog from './WorkoutCompleteDialog'
import {Runner} from './runner'
import appContext from "./appContext"
import {calculateNumberOfTicks, generateLength} from './util'

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function WorkoutDialog({workoutDef, close}) {
  const { setTitle, actualPower, setActualPower, targetPower, setTargetPower, actualCadence, setActualCadence, btConnector, profile } = useContext(appContext)
  const [runner, setRunner] = useState(null)
  const [workoutComplete, setWorkoutComplete] = useState(false)
  const [tick, setTick] = useState(0)
  const [intervalLength, setIntervalLength] = useState(0)
  const [intervalProgress, setIntervalProgress] = useState(0)
  const [paused, setPaused] = useState(true)
  const totalWorkoutTime = generateLength(calculateNumberOfTicks(workoutDef))
  const currentTime = generateLength(tick)
  const formatedIntervalLength = generateLength(intervalLength)
  const formatedIntervalProgress = generateLength(intervalProgress)

  // keep the screen awake
  navigator.wakeLock.request("screen");

  function tickCallback(tickData) {
    setTargetPower(tickData.targetPower)
    setActualPower(tickData.actualPower)
    setActualCadence(tickData.cadence)
    setTick(tickData.tick)
    setIntervalLength(tickData.intervalLength)
    setIntervalProgress(tickData.intervalProgress)
  }

  useEffect(() => {
    if(workoutDef) {
      setRunner(new Runner(workoutDef, tickCallback, complete, btConnector, profile.ftp))
    }
    
  },[workoutDef])

  function complete() {
    setWorkoutComplete(true)
  }

  function userComplete() {
    runner.complete()
    complete()
  }
  function start() {
    runner.start();
    setPaused(false)
  }

  function pause() {
    runner.pause()
    setPaused(true)
  }

  function onClose() {
    setActualPower(0)
    setTargetPower(0)
    setTick(0)
    setPaused(true)
    close()
  }

  const workoutData = runner && runner.getWorkoutData();
  let actionButton = (<Pause onClick={pause} />)
  if(paused) {
    actionButton = (<PlayArrow onClick={start} />)
  }
  return (
    <Dialog
      fullScreen
      open={!!workoutDef}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <Close />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {workoutDef && workoutDef.name}
          </Typography>
          <Button autoFocus color="inherit" onClick={userComplete}>
            Complete
          </Button>
        </Toolbar>
      </AppBar>
        <Container maxWidth="100wh" sx={{ height: '82vh', mt:"2vh" }} >
          <Grid container spacing={1} sx={{alignItems: "stretch"}}>
            <Grid item xs={4}>
              <WorkoutTimeCard totalTime={totalWorkoutTime} currentTime={currentTime} intervalTime={formatedIntervalLength} currentIntervalTime={formatedIntervalProgress}/>
            </Grid>
            <Grid item xs={4}>
              <PowerCard targetPower={targetPower} actualPower={actualPower}/>
            </Grid>
            {/* <Grid display="flex" justifyContent="center" alignItems="center" xs={4}>
              <HeartCard/>
            </Grid> */}
            <Grid item xs={4}>
              <CadenceCard cadence={actualCadence}/>
            </Grid>
            <Grid item display="flex" justifyContent="center" alignItems="center" xs={12}>
              <WorkoutGraph2 workoutDef={workoutDef} ftp={profile.ftp} workoutData={workoutData}/>
            </Grid>
          </Grid>
          <Fab color="primary" aria-label="add" sx={{
            position: "fixed",
            bottom: (theme) => theme.spacing(2),
            right: (theme) => theme.spacing(2)}}>
          {actionButton}
          </Fab>
        </Container>
      <WorkoutCompleteDialog open={workoutComplete} workoutData={workoutData}/>
    </Dialog>
  );
}