import * as React from 'react';
import {useLocation} from "wouter";
import Card from '@mui/material/Card';
import Paper from '@mui/material/Paper';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack'

export default function WorkoutCard({cadence}) {
  return (
    <Card variant='outlined' sx={{display: 'flex', flexDirection: 'column', height: '100%', bgcolor:'#ebf0ff', justifyContent: "center"}}>
      <CardContent>
        <Stack direction="column" sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography color="text.secondary" sx={{ typography: { sm: 'h3', xs: 'h5' } }}>
            Cadence
          </Typography>
          <Typography color="text.secondary" sx={{ typography: { sm: 'h3', xs: 'h5' } }}>
            {cadence}
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  );
}