import * as React from 'react';
import Card from '@mui/material/Card';
import Paper from '@mui/material/Paper';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack'
import Divider from '@mui/material/Divider';

export default function WorkoutTimeCard({totalTime, currentTime, intervalTime, currentIntervalTime}) {
  return (
    <Card variant='outlined' sx={{display: 'flex', flexDirection: 'column', bgcolor:'#ebf0ff', height: '100%', justifyContent: "center"}}>
      <CardContent>
        <Stack direction="column" sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h5" color="text.secondary">
            Workout Length
          </Typography>
          <Typography variant="h5" color="text.secondary">
            {totalTime}
          </Typography>
        </Stack>
        <Divider />
        <Stack direction="column" sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h5" color="text.secondary">
            Workout Time
          </Typography>
          <Typography variant="h5" color="text.secondary">
            {currentTime}
          </Typography>
        </Stack>
        <Divider />
        <Stack direction="column" sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h5" color="text.secondary">
            Interval Length
          </Typography>
          <Typography variant="h5" color="text.secondary">
            {intervalTime}
          </Typography>
        </Stack>
        <Divider />
        <Stack direction="column" sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h5" color="text.secondary">
            Interval Time Left
          </Typography>
          <Typography variant="h5" color="text.secondary">
            {currentIntervalTime}
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  );
}