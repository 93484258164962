export class BtConnector {
  constructor() {
    console.log("btconnector constructor")
    this.cumCrankRevs = 0
    this.lastCrankTime = null
  }

  async scan() {
    const device = await navigator.bluetooth.requestDevice({
      filters: [{
          services: [0x1826, 0x1818]
      }]
    })

    return device;
  }

  async connect(device) {
    return new Promise(async (resolve, reject) => {
      try {
        this.server = await device.gatt.connect()
        this.powerService = await this.server.getPrimaryService(0x1818/*0x1826*/)
        this.ftmsService = await this.server.getPrimaryService(0x1826)
        resolve()
      } catch (e) {
        reject(e)
      }
    })
  }

  getSupportedServices() {
    return {
      powerService: this.powerService,
      ftmsService: this.ftmsService
    }
  }

  handlePowerChange(event) {
    //console.log(event.target.value.getUint16(0))
    let power = 0
    let currentRevs = 0
    let currentCrankTime = 0
    const buffer = new ArrayBuffer(16)
    const dv = new DataView(buffer)
    // There's an endian problem difference here necesitating the backwards order here
    dv.setUint8(0, event.target.value.getUint8(3))
    dv.setUint8(1, event.target.value.getUint8(2))
    power = dv.getUint16(0)

    // Crank Revolution Data (Cadence)
    dv.setUint8(0, event.target.value.getUint8(13))
    dv.setUint8(1, event.target.value.getUint8(12))
    currentRevs = dv.getUint16(0)

    // last crank time
    dv.setUint8(0, event.target.value.getUint8(15))
    dv.setUint8(1, event.target.value.getUint8(14))
    currentCrankTime = dv.getUint16(0);
    
    const instantaneousCadence = ((currentRevs - this.cumCrankRevs) / ((currentCrankTime - this.lastCrankTime) / 1024)*60)
    this.cumCrankRevs = currentRevs
    this.lastCrankTime = currentCrankTime
    return {
      power,
      cadence: Math.round(instantaneousCadence) || 0
    }
  }

  async subscribeToPowerNotifications(callback) {
    if(this.powerService) {
      const powerChar = await this.powerService.getCharacteristic(0x2a63)
      powerChar.addEventListener('characteristicvaluechanged', (event) => {
        const result = this.handlePowerChange(event)
        callback(result.power, result.cadence)
      })
      powerChar.startNotifications()
    }
  }

  handleFTMSControlPointChanged(event) {
    console.log(event)
  }

  async initializeFTMSConnection() {
    if(this.ftmsService) {
      try {
        const ftmsControlPoint = await this.ftmsService.getCharacteristic(0x2AD9)
        await ftmsControlPoint.addEventListener('characteristicvaluechanged', this.handleFTMSControlPointChanged)
        
        // Delay for android,  It doesn't seem to wait for the prior promise before resolving 
        await new Promise(resolve => setTimeout(resolve, 500));
        await ftmsControlPoint.startNotifications()
        
        // Add a delay of 100 milliseconds
        await new Promise(resolve => setTimeout(resolve, 500));
        
        // request control of ftms device
        let requestControlResponse = await ftmsControlPoint.writeValue(Uint8Array.of(0))


        console.log(requestControlResponse)
      } catch(e) {
        console.log("Error initializing FTMS", e)
      }
    }
  }

  async setTargetPower(power) {
    if(this.ftmsService) {
      try {
        const dv = new DataView(new ArrayBuffer(2), 0);
        dv.setUint16(0, power);
        const uarr = new Uint8Array(dv.buffer);
        // 5 is op code to set power, need to reverse byte order for power numbers
        const uarrReversed = Uint8Array.of(5, uarr[1], uarr[0]) 

        const ftmsControlPoint = await this.ftmsService.getCharacteristic(0x2AD9)
        const requestControlResponse = await ftmsControlPoint.writeValue(uarrReversed)
        console.log(requestControlResponse)
      } catch (e) {
        console.log("Failed to set Target Power on FTMS Device", e)
      }

    }
  }
}