import { getWorkoutsList, saveWorkouts } from "./util"

export class Recorder {
  #workoutData
  #startDateTime
  #sourceWorkoutId
  constructor(workoutId) {
    this.#workoutData = []
    this.#sourceWorkoutId = workoutId
  }

  start(date) {
    this.#startDateTime = date
  }

  recordTick(data) {
    const tickData = {
      power: data.actualPower,
      cadence: data.Cadence,
      tick: data.tick
    }

    this.#workoutData.push(tickData)
  }

  complete() {
    console.log(JSON.stringify(this.#workoutData))
    const newWorkout = {
      id: crypto.randomUUID(),
      sourceWorkoutId: this.#sourceWorkoutId,
      startDateTime: this.#startDateTime,
      workoutData: this.#workoutData
    }
    const workouts = getWorkoutsList()
    workouts.push(newWorkout)
    saveWorkouts(workouts)
  }

  getWorkoutData() {
    return this.#workoutData;
  }
}