import {useContext} from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {List, ListItem, ListItemText} from '@mui/material';
import Divider from '@mui/material/Divider';
import {generateLength, calculateAvgPower, calculatekjs} from './util';
import appContext from "./appContext"
import sourceWorkouts from './Workouts/0.json'
import WorkoutGraph2 from './WorkoutGraph2';

export default function WorkoutSummaryCard(props) {
  const {profile : {ftp}} = useContext(appContext)
  const setCompletedWorkoutId = props.setCompletedWorkoutId
  
  function showCompleteWorkoutDialog(evt) {
    setCompletedWorkoutId(props.workout.id)
  }

  const {startDateTime, sourceWorkoutId, workoutData} = props.workout
  const workoutDef = sourceWorkouts.find((sourceWorkout) => sourceWorkout.id === sourceWorkoutId)
  const kjs = calculatekjs(workoutData)
  return (
    <Card sx={{ minWidth: 345 }}>
      <CardMedia>
        <WorkoutGraph2 workoutDef={workoutDef} ftp={ftp} workoutData={workoutData} dimensions={{width: 320, height: 180, unit:"px"}} />
      </CardMedia>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {workoutDef.name}
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="Date" secondary={new Date(startDateTime).toDateString()} />
          </ListItem>
          <Divider variant="fullWidth" />
          <ListItem>
            <ListItemText primary="Length" secondary={generateLength(workoutData.length)} />
          </ListItem>
          <Divider variant="fullWidth" /> 
          <ListItem>
            <ListItemText primary="Kilojoules" secondary={kjs} />
          </ListItem>
          <Divider variant="fullWidth" />
          <ListItem>
            <ListItemText primary="Average Power" secondary={calculateAvgPower(workoutData)} />
          </ListItem>
        </List>
      </CardContent>
      <CardActions>
        <Button size="small" onClick={showCompleteWorkoutDialog}>Details</Button>
      </CardActions>
    </Card>
  );
}