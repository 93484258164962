import { useContext, useEffect, useState} from "react";
import appContext from "./appContext";
import {useParams} from "wouter";
import {Card, Fab} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; 
import PowerCard from './PowerCard'
import CadenceCard from './CadenceCard'
import HeartCard from './HeartCard'
import {PlayArrow} from '@mui/icons-material';
import {Runner} from './runner'
import WorkoutCompleteDialog from './WorkoutCompleteDialog'

export default function WorkoutRunner() {
  const { setTitle, actualPower, setActualPower, targetPower, setTargetPower, btConnector } = useContext(appContext)
  const routeParams = useParams()
  const [runner, setRunner] = useState({})
  const [workoutComplete, setWorkoutComplete] = useState(false)

  useEffect(() => {
    const workoutId = routeParams.id
    import(`./Workouts/${workoutId}.json`).then(async (workoutDef) => {
      await setRunner(new Runner(workoutDef.workout, setTargetPower, setActualPower, btConnector))
    }).catch((e) => {
      console.log(e); // should probably display this in some sort of toast
    })
  }, [routeParams.id, setRunner, setTargetPower, btConnector]);

  useEffect(() => {
    const subscribe = async () => {
      await btConnector.subscribeToPowerNotifications(setActualPower)
    }
    subscribe()
  }, [btConnector, setActualPower])

  function complete() {
    setWorkoutComplete(true)

  }

  function start() {
    runner.start(complete);
  }

  //setTitle("Workout Runner")
  return (
    <>
      <Card sx={{ height: "82vh" }}>
        <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid display="flex" justifyContent="center" alignItems="center" xs={4}>
              <PowerCard targetPower={targetPower} actualPower={actualPower}/>
          </Grid>
          <Grid display="flex" justifyContent="center" alignItems="center" xs={4}>
            <CadenceCard/>
          </Grid>
          <Grid  display="flex" justifyContent="center" alignItems="center" xs={4}>
            <HeartCard/>
          </Grid>
        </Grid>
        <Fab color="primary" aria-label="add" sx={{
          position: "fixed",
          bottom: (theme) => theme.spacing(2),
          right: (theme) => theme.spacing(2)}}>
          <PlayArrow onClick={start}/>
        </Fab>
      </Card>
      <WorkoutCompleteDialog open={workoutComplete}/>
    </>

  );
}