import * as React from 'react';
import {useLocation} from "wouter";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

export default function WorkoutCard() {
  return (
    <Card sx={{ minWidth: "30vw", mt:"5px", ml:"5px", mr:"5px" }}>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          Heart
        </Typography>
        <Typography variant="body2" color="text.secondary">
          130
        </Typography>
      </CardContent>
    </Card>
  );
}