import {React, useContext} from 'react';
import appContext from "./appContext";
import Grid from '@mui/material/Unstable_Grid2'; 
import Button from '@mui/material/Button';
import DeviceCard from './DeviceCard';

export default function DevicesList() {
  const {powerService, ftmsService, setPowerService, setFtmsService, btConnector,} = useContext(appContext)
  const powerComponent = powerService ? <DeviceCard name={powerService.device.name} type="power"/> : undefined
  const ftmsComponment = ftmsService ? <DeviceCard name={ftmsService.device.name} type="ftms"/> : undefined

  async function scan() {
    try {
      const device = await btConnector.scan()
      await btConnector.connect(device)
      const services = btConnector.getSupportedServices()

      if(services) {
        setPowerService(services.powerService)
        setFtmsService(services.ftmsService)
      }

      btConnector.initializeFTMSConnection()
    } catch(e) {
      console.log("Error connecting to bluetooth device", e)
    }
  }

  return (
      <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid xs={6}>
          {powerComponent}
        </Grid>
        <Grid xs={6}>
          {ftmsComponment}
        </Grid>
        <Grid xs={12}>
          <Button variant="contained" size="large" onClick={scan}>
            Connect to Fitness Devices
          </Button>
        </Grid>
      </Grid>
  )
}