import * as React from 'react';
import {useLocation} from "wouter";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';


export default function WorkoutCard({preview, workoutDef, setCurrentWorkoutDef}) {
  const [location, navigate] = useLocation()
  
  function loadWorkout(evt) {
    //navigate(`/Workout/${workoutDef.id}`)
    setCurrentWorkoutDef(workoutDef)
  }

  return (
    <Card sx={{ minWidth: 345 }}>
      <CardMedia>
        {preview}
      </CardMedia>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {workoutDef.name}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          This is a popular workout that give a good estimation of FTP and some more stuff
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small" onClick={loadWorkout}>Load Workout</Button>
      </CardActions>
    </Card>
  );
}