import {useState, useEffect, useContext} from 'react'
import Grid from '@mui/material/Unstable_Grid2'
import WorkoutCard from './WorkoutCard'
import appContext from "./appContext"
import WorkoutDialog from "./WorkoutDialog"
import WorkoutGraph2 from './WorkoutGraph2'

export default function WorkoutsList(props) {
  const [loadedWorkouts, setLoadedWorkouts] = useState([])
  const [currentWorkoutDef, setCurrentWorkoutDef] = useState(null)
  const {profile : {ftp}} = useContext(appContext)

  useEffect(() => {
    import(`./Workouts/0.json`).then(async (workoutsList) => {
      const loadedDefs = []
      for(let i = 0; i < workoutsList.length; i++) {
        loadedDefs.push({preview: (<WorkoutGraph2 workoutDef={workoutsList[i]} ftp={ftp} dimensions={{width: 320, height: 180, unit: "px"}} />), def: workoutsList[i]})
      }
      setLoadedWorkouts(loadedDefs)
    }).catch((e) => {
      console.log(`failed to load workouts list`, e);
    })
    
  }, []);

  const workoutCards = loadedWorkouts.map((workoutDef) => {
    return (
      <Grid display="flex" justifyContent="center" alignItems="center" xs={12} s={12} md={6} lg={4}>
        <WorkoutCard preview={workoutDef.preview} workoutDef={workoutDef.def} setCurrentWorkoutDef={setCurrentWorkoutDef}/>
      </Grid>
    )
  })

  return (
    <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      {workoutCards}
      <WorkoutDialog workoutDef={currentWorkoutDef} close={() => setCurrentWorkoutDef(null)} />
    </Grid>
  );
}