import {forwardRef, useContext, useState} from 'react';
import {Close, Delete} from '@mui/icons-material';
import {Container, Grid, Slide, Typography, IconButton, Toolbar, AppBar, Dialog, Button, List, ListItem, ListItemText, ListItemButton, ListItemIcon, Divider} from '@mui/material'

import WorkoutGraph2 from './WorkoutGraph2';

import appContext from "./appContext"
import {generateLength, calculateAvgPower, getWorkoutsList, calculatekjs, saveWorkouts} from './util'
import sourceWorkouts from './Workouts/0.json'

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CompletedWorkoutDialog({completedWorkout, close}) {
  const workoutDef = sourceWorkouts.find((sourceWorkout) => {
    return completedWorkout ? sourceWorkout.id === completedWorkout.sourceWorkoutId : false
  })
  const { profile } = useContext(appContext)

  const workoutsList = getWorkoutsList()
  const workout = workoutsList.find((workout) => workout.id === completedWorkout?.id)
  const avgPower = workout && workout.workoutData ? calculateAvgPower(workout.workoutData) : 0
  const kjs = workout && workout.workoutData ? calculatekjs(workout.workoutData): 0

  function onClose() {
    close()
  }

  function onDelete() {
    const workoutsList = getWorkoutsList();
    const workoutId = completedWorkout.id;

    const index = workoutsList.findIndex(workout => workout.id === workoutId);
    if (index !== -1) {
        workoutsList.splice(index, 1);
        saveWorkouts(workoutsList)
    }
    close()
  }

  //const graph = undefined
  return (
    <Dialog
      fullScreen
      open={!!workoutDef}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <Close />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {workoutDef && workoutDef.name}
          </Typography>
        </Toolbar>
      </AppBar>
        <Container maxWidth="100wh" sx={{ height: '82vh', mt:"2vh" }} >
        <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid display="flex" justifyContent="center" alignItems="center" xs={12}>
            <WorkoutGraph2 workoutDef={workoutDef} ftp={profile.ftp} workoutData={completedWorkout?.workoutData} dimensions={{height: '360', width: '640', unit: "px"}} />
          </Grid>
          <Grid item xs={12}>
            <List>
              <ListItem>
                <ListItemText primary="Date" secondary={new Date(completedWorkout?.startDateTime).toDateString()} />
              </ListItem>
              <Divider variant="fullWidth" />
              <ListItem>
                <ListItemText primary="Length" secondary={generateLength(completedWorkout?.workoutData.length)} />
              </ListItem>
              <Divider variant="fullWidth" /> 
              <ListItem>
                <ListItemText primary="Kilojoules" secondary={kjs} />
              </ListItem>
              <Divider variant="fullWidth" />
              <ListItem>
                <ListItemText primary="Average Power" secondary={avgPower} />
              </ListItem>
              <ListItem>
                <ListItemButton>
                  <ListItemIcon>
                    <Delete />
                  </ListItemIcon>
                  <ListItemText primary="Delete" onClick={onDelete}/>
                </ListItemButton>
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </Container>
    </Dialog>
  );
}