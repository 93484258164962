import {Recorder} from './Recorder'
import { MockBTConnector } from './mockBTConnector'
import { calculateNumberOfTicks } from './util'

export class Runner {
  constructor(workoutDef, tickCallback, complete, btConnector, ftp) {
    this.workoutDef = workoutDef
    this.length = calculateNumberOfTicks(workoutDef)
    this.currentTick = 0
    this.tickCallback = tickCallback
    this.completeCallback = complete
    this.btConnector = btConnector//MockBTConnector//btConnector
    this.lastTargetPower = 0
    this.recorder = new Recorder(workoutDef.id)
    this.actualPower = 0
    this.cadence = 0
    this.ftp = ftp || 150
    //this.btConnector.initializeFTMSConnection()
    this.btConnector.subscribeToPowerNotifications(this.extractFromPowerNotification.bind(this))
  }

  extractFromPowerNotification(power, cadence) {
    this.actualPower = power
    this.cadence = cadence
    // this.cadence = data.cadence going to have to go back and modify btConnector to handle cadence
  } 

  start() {
    this.recorder.start(new Date())
    let intervalProgress = 0
    this.intervalId = setInterval(() => {
      if(this.currentTick >= this.length) {
        this.complete()
        this.completeCallback()
      }
      
      const {targetPower, intervalLength} = this.lookupIntervalData(this.currentTick)
      if(targetPower !== this.lastTargetPower) {
        this.lastTargetPower = targetPower
        this.btConnector.setTargetPower(targetPower)
        intervalProgress = intervalLength
      }

      const tickData = {
        targetPower: targetPower,
        actualPower: this.actualPower,
        cadence: this.cadence,
        tick: this.currentTick,
        intervalLength,
        intervalProgress
      }
      
      this.recorder.recordTick(tickData)
      this.tickCallback(tickData)

      this.currentTick++
      intervalProgress--
    }, 1000)
  }

  lookupIntervalData(tick) {
    const intervals = this.workoutDef.intervals
    let timeSofar = 0;
    let targetPower = 0;
    let intervalLength = 0;
    for(let i = 0; i < intervals.length; i++) {
      const currentInterval = intervals[i]
      timeSofar += currentInterval.length
      if(tick < timeSofar) {
        targetPower = currentInterval.target
        intervalLength = currentInterval.length
        break;
      }
    }
    return {
      targetPower: (targetPower/100) * this.ftp,
      intervalLength
    }
  }

  lookupCurrentIntervalLength

  pause() {
    clearInterval(this.intervalId)
  }

  getWorkoutData() {
    return this.recorder.getWorkoutData();
  }

  complete() {
    clearInterval(this.intervalId)
    this.recorder.complete()
  }
}