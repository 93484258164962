import {React, useEffect, useRef, useState} from 'react';

export function generateLength(secondsLeft, skipSeconds) {
    const hours = Math.floor(secondsLeft/3600) || "00"
  
    secondsLeft = secondsLeft - (hours * 3600)
    let minutes = Math.floor(secondsLeft / 60) || "00"
    if(minutes < 10 && typeof minutes === "number") {
      minutes = "0" + minutes
    }
    let seconds = (secondsLeft - (minutes * 60))
  
    if(seconds < 10) {
      seconds = "0" + seconds
    }
    
    return skipSeconds ? `${hours}:${minutes}` : `${hours}:${minutes}:${seconds}`
  }

export default function WorkoutGraph2({workoutDef, ftp, workoutData, dimensions}) {
    const sizeDiv = useRef()
    const [width, setWidth] = useState(0)
    const [height, setHeight] = useState(0)
    const needsExpandingDiv = !!!dimensions
    useEffect(() => {
        const resizeObserver = new ResizeObserver((event) => {
            // Depending on the layout, you may need to swap inlineSize with blockSize
            // https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserverEntry/contentBoxSize
            setWidth(event[0].contentBoxSize[0].inlineSize);
            setHeight(event[0].contentBoxSize[0].blockSize);
        });

        if(needsExpandingDiv){
            resizeObserver.observe(sizeDiv.current);
        }
    }, []);

    const rect = sizeDiv?.current?.getBoundingClientRect()
    const divHeight = needsExpandingDiv ? Math.abs(window.innerHeight - (rect?.top || 18)) : dimensions.height + dimensions.unit 
    const divWidth = needsExpandingDiv ? "100%" : dimensions.width + dimensions.unit
    const graphWidth = needsExpandingDiv ? sizeDiv?.current?.clientWidth : dimensions.width
    const graphHeight = needsExpandingDiv ? sizeDiv?.current?.clientHeight : dimensions.height

    let yAxisAllowance = 0
    let xAxisAllowance = 0
    if(true /*drawAxis*/) {
        yAxisAllowance = 32
        xAxisAllowance = 16
    }

    let workoutLength = 0
    if (workoutDef?.intervals) {
        workoutLength = workoutDef.intervals.reduce((acc, cur) => {
            return acc += cur.length
        }, 0)
    }

    let biggestPower = 0
    if (workoutDef?.intervals) {
        biggestPower = workoutDef.intervals.reduce((acc, cur) => {
            return Math.max(acc, (cur.target/100) * ftp)
        }, 0)
    }

    biggestPower = Math.max(biggestPower, ftp)
    biggestPower += biggestPower * 0.2 // add headroom so the graph doen't hit the top

    let widthSoFar = 0
    const intervalRects = workoutDef?.intervals.map((interval) => {
        let intWidth = ((interval.length/workoutLength) * (graphWidth - yAxisAllowance))
        const intHeight = (((graphHeight)/biggestPower) * ((interval.target/100) * ftp))
        const intervalRect = (
            <rect x={yAxisAllowance+widthSoFar} y={graphHeight - intHeight - xAxisAllowance} width={intWidth} height={intHeight} fill='tomato' />
        )
        widthSoFar+=intWidth
        return intervalRect
    })

    const ftpY = (graphHeight-xAxisAllowance) - ((ftp/biggestPower) * (graphHeight))
    const ftpLine = (<line x1={yAxisAllowance} y1={ftpY} x2={graphWidth} y2={ftpY} stroke="blue" strokeWidth={0.5}/>)
    
    let powerPath = null
    if(workoutData) {
        let path = `M ${yAxisAllowance} ${graphHeight - xAxisAllowance} `
        let second=0
        for(second=0; second<workoutData.length; second++) {
            const power = workoutData[second]
            const powerY = (graphHeight - xAxisAllowance) - (((graphHeight)/biggestPower) * power.power)
            const powerX = yAxisAllowance + (second/workoutLength) * (graphWidth - yAxisAllowance)
            path += `L ${powerX} ${powerY} `
        }
        powerPath = (<path d={path} stroke="black" fill="transparent" strokeWidth={0.5}/>)
    }
    
    let axis = null
    let timeMarkers = []
    let powerMarkers = []
    let drawAxis = true
    if (drawAxis) {
        let second
        for(second = 300; second<workoutLength; second+=300) {
          let timeMarkerPosition = (second/workoutLength) * (graphWidth - yAxisAllowance)
          timeMarkers.push(<line x1={yAxisAllowance+timeMarkerPosition} y1={graphHeight - xAxisAllowance} x2={yAxisAllowance+timeMarkerPosition} y2={graphHeight - xAxisAllowance - (graphHeight * 0.05)} stroke="grey" strokeWidth={1}/>)
          timeMarkers.push(<text x={yAxisAllowance+timeMarkerPosition - 16} y={graphHeight - xAxisAllowance + 12} style={{"font-size": "12px", "fill": "grey"}}>{generateLength(second, true)}</text>)
        }
        let watts;
        for (watts = 50; watts < biggestPower; watts +=50) {
            let powerMarkerPosition = (graphHeight - xAxisAllowance) - ((watts/biggestPower) * graphHeight)
            powerMarkers.push(<line x1={yAxisAllowance} y1={powerMarkerPosition} x2={yAxisAllowance + 12} y2={powerMarkerPosition} stroke="grey" strokeWidth={1}/>)
            powerMarkers.push(<text x="0" y={powerMarkerPosition + 3} style={{"font-size": "12px", "fill": "grey"}}>{watts}</text>)
        }
        axis = (
            <>
              <line x1={yAxisAllowance} y1="0" x2={yAxisAllowance} y2={graphHeight - xAxisAllowance} stroke="grey" strokeWidth={0.5}/> 
              <line x1={yAxisAllowance} y1={graphHeight - xAxisAllowance} x2="320" y2={graphHeight - xAxisAllowance} stroke="grey" strokeWidth={0.5}/>
            </>
          )
    }

    let wholeGraph = (
        <svg viewBox={`0 0 ${graphWidth} ${graphHeight}`} height={`${divHeight}`} width={divWidth}>
            {intervalRects}
            {ftpLine}
            {powerPath}
            {axis}
            {timeMarkers}
            {powerMarkers}
        </svg> 
    )
    if(needsExpandingDiv) {
        wholeGraph = (
            <div ref={sizeDiv} style={{width: divWidth, height:`${divHeight}`}}>
                {wholeGraph}
            </div>
        )
    }

    return (
        <>
            {wholeGraph}
        </>
    );
}