import {React, useContext} from 'react';
import { Dialog, DialogTitle, DialogContent, Typography, DialogActions, Button } from '@mui/material';
import { useLocation} from "wouter";
import appContext from "./appContext"

export default function WorkoutCompleteDialog(props) {
  const { open } = props;
  const [location, navigate] = useLocation()
  const {setBottomNaveActiveButton} = useContext(appContext)

  const handleClose = () => {
  };

  const navigateToSummary = () => {
    navigate("/History")
    setBottomNaveActiveButton(3)
  }

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Workout Complete!</DialogTitle>
      <DialogContent dividers>
        <Typography gutterBottom>
          Some motivational message about how hard that was and how hard you worked!
        </Typography>
        <Typography gutterBottom>
          Another message about recovery and proper nutrition!
        </Typography>
      </DialogContent>
      <DialogActions>
          <Button autoFocus onClick={navigateToSummary}>
            Workout Summary
          </Button>
        </DialogActions>
    </Dialog>
  );
}