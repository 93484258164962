import * as React from 'react';
import {Typography, Divider} from '@mui/material';
import {Bolt, Favorite} from '@mui/icons-material';
import Card from '@mui/material/Card';

export default function DeviceCard(props) {
  const {name, type} = props
  let Icon = Bolt;
  let description = "Power Source"
  switch(type){
    case "heart":
      Icon = Favorite
      description = "Heart Rate Monitor"
      break;
    case "ftms":
      description = "Controllable Smart Trainer"
      break;
    default:
      break;

  }
  return (
    <Card>
      <Icon />
      <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>{description}</Typography>
      <Divider variant="middle"/>
      <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>{name}</Typography>
    </Card>
  );
}