import * as React from 'react';
import Card from '@mui/material/Card';
import Paper from '@mui/material/Paper';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack'

export default function WorkoutCard(props) {
  const {targetPower, actualPower} = props;
  return (
    <Card variant='outlined' sx={{display: 'flex', flexDirection: 'column', height: '100%', bgcolor: '#ebf0ff', justifyContent: "center"}}>
      <CardContent>
        <Stack direction="column" sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h3" color="text.secondary" sx={{ typography: { sm: 'h3', xs: 'h5' } }}>
            Power
          </Typography>
          <Typography variant="h3" color="text.secondary">
            {actualPower}
          </Typography>
        </Stack>
        <Divider />
        <Stack direction="column" sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography color="text.secondary" sx={{ typography: { sm: 'h3', xs: 'h5' } }}>
            Target
          </Typography>
          <Typography variant="h3" color="text.secondary">
            {targetPower}
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  );
}