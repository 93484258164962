import { createContext } from "react";

const appContext = createContext({
  title: false,
  setTitle: (title) => {},
  actualPower: {},
  setActualPower: (actualPower) => {},
  actualCadence: {},
  setActualCadence: (actualCadence) => {},
  targetPower: 0,
  setTargetPower: (targetPower) => {},
  powerService: {},
  setPowerService: (powerService) => {},
  ftmsService: {},
  setFtmsService: (ftmsService) => {},
  btConnector: {},
  setBTConnector: (btConnector) => {}
});

export default appContext;