import {useState} from 'react';
import './App.css';
import DevicesList from './DevicesList'
import WorkoutsList from './WorkoutsList';
import { Route , useLocation} from "wouter";
import AppBar from './MainAppBar'
import { BottomNavigation, BottomNavigationAction, Container, Box, Paper } from '@mui/material';
import {Bluetooth, FitnessCenter, List, Build} from '@mui/icons-material';
import appContext from "./appContext";
import WorkoutRunner from './WorkoutRunner';
import HistoryList from './HistoryList';
import { BtConnector } from './btConnector';

function App() {
  let [bottomNavActiveButton, setBottomNaveActiveButton] = useState(0)
  const [title, setTitle] = useState("Devices")
  const [actualPower, setActualPower] = useState(0)
  const [actualCadence, setActualCadence] = useState(0)
  const [targetPower, setTargetPower] = useState(0)
  const [powerService, setPowerService] = useState(0)
  const [ftmsService, setFtmsService] = useState(0)
  const [btConnector, setBTConnector] = useState(new BtConnector())
  const [profile, setProfile] = useState(JSON.parse(localStorage.getItem("profile")) || {ftp: 100})
  const [location, navigate] = useLocation()


  return (
    <div className="App">
       <Container maxWidth="100wh">
        <appContext.Provider value={{title, setTitle, actualPower, setActualPower, actualCadence, setActualCadence, targetPower, setTargetPower, powerService, setPowerService, ftmsService, setFtmsService, btConnector, setBTConnector, profile, setProfile, bottomNavActiveButton, setBottomNaveActiveButton}}>
          <AppBar title={title}/>
          <Box sx={{pt:"10px", pb:"56px"}}>
            <Route path="/" component={DevicesList} />
            <Route path="/Devices" component={DevicesList} />
            <Route path="/Workouts" component={WorkoutsList} />
            <Route path="/Workout/:id" component={WorkoutRunner}></Route>
            <Route path="/Builder">Builder</Route>
            <Route path="/History" component={HistoryList} />
          </Box>
          <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
            <BottomNavigation showLabels value={bottomNavActiveButton}
                onChange={(event, newValue) => {
                  setBottomNaveActiveButton(newValue);
                  switch(newValue) {
                    case 0:
                      navigate("/Devices")
                      setTitle("Devices")
                      break;
                    case 1:
                      navigate("/Workouts")
                      setTitle("Workouts")
                      break;
                    case 2:
                      navigate("/Builder")
                      setTitle("Workout Builder")
                      break;
                    case 3:
                      navigate("/History")
                      setTitle("Completed Workouts")
                      break;
                    default:
                      break;
                  }
                }}
            >
                <BottomNavigationAction label="Connect Devices" icon={<Bluetooth />} />
                <BottomNavigationAction label="Choose Workout" icon={<FitnessCenter />} />
                <BottomNavigationAction label="Workout Builder" icon={<Build />} />
                <BottomNavigationAction label="Workout History" icon={<List />} />
            </BottomNavigation>  
          </Paper>
        </appContext.Provider>
      </Container>
    </div>
  );
}

export default App;