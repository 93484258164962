export function generateLength(secondsLeft, skipSeconds) {
  const hours = Math.floor(secondsLeft/3600) || "00"

  secondsLeft = secondsLeft - (hours * 3600)
  let minutes = Math.floor(secondsLeft / 60) || "00"
  if(minutes < 10 && typeof minutes === "number") {
    minutes = "0" + minutes
  }
  let seconds = (secondsLeft - (minutes * 60))

  if(seconds < 10) {
    seconds = "0" + seconds
  }
  
  return skipSeconds ? `${hours}:${minutes}` : `${hours}:${minutes}:${seconds}`
}

export function calculateNumberOfTicks(workoutDef) {
  let lengthSoFar = 0
    if(workoutDef) {
      workoutDef.intervals.forEach((interval) =>
      lengthSoFar += interval.length
    )
  }
  return lengthSoFar;
}

export function calculateAvgPower(workoutData) {
  const totalWatts = workoutData.reduce((acc, cur) => {
    return acc += cur.power
  }, 0)
  const avgWatts = Math.floor(totalWatts/workoutData.length)
  return avgWatts
}

export function calculatekjs(workoutData) {
  const kjs = workoutData.reduce((acc, tick) => {
      return acc + (tick.power/1000)
    }, 0
  )
  return Math.round(kjs * 100) / 100
}

export function getWorkoutsList() {
  const workouts = localStorage.getItem("workouts") || ""
  return (workouts && JSON.parse(workouts)) || []
}

export function saveWorkouts(workouts) {
  localStorage.setItem("workouts", JSON.stringify(workouts))
}

export function generateGraph(workoutDef, ftp, height, width, powerData, drawAxis) {
  let yAxisAllowance = 0
  let xAxisAllowance = 0
  if(true /*drawAxis*/) {
    yAxisAllowance = 6
    xAxisAllowance = 6
  }
  const workoutLength = workoutDef.intervals.reduce((acc, cur) => {
    return acc += cur.length
  }, 0)

  let biggestPower = workoutDef.intervals.reduce((acc, cur) => {
    return Math.max(acc, (cur.target/100) * ftp)
  }, 0)

  biggestPower = Math.max(biggestPower, ftp)
  biggestPower += biggestPower*0.2 // add headroom so the graph doen't hit the top

  let widthSoFar = 0
  const intervalRects = workoutDef.intervals.map((interval) => {
    let intWidth = ((interval.length/workoutLength) * (320 - yAxisAllowance))
    const intHeight = (((180)/biggestPower) * ((interval.target/100) * ftp))
    const intervalRect = (
      <rect x={yAxisAllowance+widthSoFar} y={180 - intHeight - xAxisAllowance} width={intWidth} height={intHeight} fill='tomato' />
    )
    widthSoFar+=intWidth

    return intervalRect
  })

  const ftpY = (180-xAxisAllowance) - ((ftp/biggestPower) * (180))
  const ftpLine = (<line x1={yAxisAllowance} y1={ftpY} x2="320" y2={ftpY} stroke="blue" strokeWidth={0.5}/>)

  let powerPath = null
  if(powerData) {
    let path = `M ${yAxisAllowance} ${180 - xAxisAllowance} `
    let second=0
    for(second=0; second<powerData.length; second++) {
      const power = powerData[second]
      const powerY = (180 - xAxisAllowance) - (((180)/biggestPower) * power.power)
      const powerX = yAxisAllowance + (second/workoutLength) * (320 - yAxisAllowance)
      path += `L ${powerX} ${powerY} `
    }
    powerPath = (<path d={path} stroke="black" fill="transparent" strokeWidth={0.5}/>)
  }

  let axis = null
  let timeMarkers = []
  let powerMarkers = []
  if(true/*drawAxis*/) {
    let second
    for(second = 300; second<workoutLength; second+=300) {
      let timeMarkerPosition = (second/workoutLength) * (320 - yAxisAllowance)
      timeMarkers.push(<line x1={yAxisAllowance+timeMarkerPosition} y1={180 - xAxisAllowance} x2={yAxisAllowance+timeMarkerPosition} y2={180 - xAxisAllowance - 3} stroke="grey" strokeWidth={0.5}/>)
      timeMarkers.push(<text x={yAxisAllowance+timeMarkerPosition} y={180 - xAxisAllowance + 5} style={{"font-size": "3px", "fill": "grey"}}>{generateLength(second, true)}</text>)
    }

    let watts;
      for (watts = 50; watts < biggestPower; watts +=50) {
        let powerMarkerPosition = (180 - xAxisAllowance) - ((watts/biggestPower) * 180)
        powerMarkers.push(<line x1={yAxisAllowance} y1={powerMarkerPosition} x2={yAxisAllowance + 3} y2={powerMarkerPosition} stroke="grey" strokeWidth={0.5}/>)
        powerMarkers.push(<text x="0" y={powerMarkerPosition + 1} style={{"font-size": "3px", "fill": "grey"}}>{watts}</text>)
      }
    axis = (
      <>
        <line x1={yAxisAllowance} y1="0" x2={yAxisAllowance} y2={180 - xAxisAllowance} stroke="grey" strokeWidth={0.5}/> 
        <line x1={yAxisAllowance} y1={180 - xAxisAllowance} x2="320" y2={180 - xAxisAllowance} stroke="grey" strokeWidth={0.5}/>
      </>
    )
  }

  return (
    <svg viewBox='0 0 320 180' height={height} width={width} preserveAspectRatio="xMidYMid meet">
      {intervalRects}
      {ftpLine}
      {powerPath}
      {axis}
      {timeMarkers}
      {powerMarkers}
    </svg>

  )
}