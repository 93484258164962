import { useState } from 'react'
import Grid from '@mui/material/Unstable_Grid2'
import WorkoutSummaryCard from './WorkoutSummaryCard'
import CompletedWorkoutDialog from './CompletedWorkoutDialog'
import sourceWorkouts from './Workouts/0.json'
import { getWorkoutsList } from './util'

export default function HistoryList() {
  const [completedWorkoutId, setCurrentWorkoutId] = useState(-1)
  const workoutsList = getWorkoutsList()
  let completedWorkout = null
  if(completedWorkoutId) {
    completedWorkout = workoutsList.find((workout) => workout.id === completedWorkoutId)
  }
  const cards = workoutsList.map((workout) => {
    const workoutDef = sourceWorkouts.find((def) => def.id === workout.id)
    return(
      <Grid display="flex" justifyContent="center" alignItems="center" xs={12} s={12} md={6} lg={4}>
        <WorkoutSummaryCard workout={workout} workoutDef={workoutDef} setCompletedWorkoutId={setCurrentWorkoutId}/>
      </Grid>
    )
  })

  return (
      <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        {cards}
        <CompletedWorkoutDialog completedWorkout={completedWorkout} close={() => setCurrentWorkoutId(null)}/>
      </Grid>
  )
}